import axios from "axios";
import elaStandardsToSkills from "../data/standards_to_skills.json";

const IS_DEBUG = false;
const API_ROOT = "https://api-test.diffit.me";
const API_ROOT_DEBUG = "https://664c-207-153-24-107.ngrok-free.app"; // TODO: Use your ngrok tunnel URL

const axiosInstance = axios.create();

export const setAxiosToken = (token) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["ngrok-skip-browser-warning"] = "true";
            return config;
        },
        (error) => Promise.reject(error),
    );
};

export async function createPacket(session, data) {
    try {
        const token = await session.getToken({ template: "userinfo" });
        setAxiosToken(token);
        const response = await axiosInstance.post(
            `${IS_DEBUG ? API_ROOT_DEBUG : API_ROOT}/packet`,
            data,
        );
        return response.data;
    } catch (error) {
        console.error("Error creating packet:", error);
        throw error;
    }
}

export async function updatePacket(session, packetId, queryString) {
    try {
        const token = await session.getToken({ template: "userinfo" });
        setAxiosToken(token);
        const response = await axiosInstance.patch(
            `${IS_DEBUG ? API_ROOT_DEBUG : API_ROOT}/packet/${packetId}?${queryString}`,
            { current: "" },
        );
        return response.data;
    } catch (error) {
        console.error("Error updating packet:", error);
    }
}

export async function createSkillsTemplate(session, packetId) {
    try {
        const token = await session.getToken({ template: "userinfo" });
        setAxiosToken(token);
        const response = await axiosInstance.post(
            `${IS_DEBUG ? API_ROOT_DEBUG : API_ROOT}/template/skills_template/${packetId}`,
            {},
        );
        return response.data;
    } catch (error) {
        console.error("Error creating skills packet:", error);
    }
}

export async function getELAStandards(session, state) {
    try {
        const validGradeLevels = new Set(["3", "4", "5", "6", "7", "8"]);
        const token = await session.getToken({ template: "userinfo" });
        setAxiosToken(token);
        const response = await axiosInstance.get(
            `${IS_DEBUG ? API_ROOT_DEBUG : API_ROOT}/skill?standard_set=${state}&subject=ELA`,
        );
        let output = response.data
            .filter((standard) =>
                standard.grade_level.some((grade) =>
                    validGradeLevels.has(grade),
                ),
            )
            .map((standard) => {
                let standardsSkills = new Set(
                    elaStandardsToSkills[standard.id] || [],
                );
                standard.mapped_common_core_standards.forEach((standard) => {
                    let skills = elaStandardsToSkills[standard] || [];
                    skills.forEach((skill) => standardsSkills.add(skill));
                });
                return {
                    ...standard,
                    skills: Array.from(standardsSkills),
                };
            })
            .filter((standard) => standard.skills.length > 0)
            .sort((a, b) => a.id.localeCompare(b.id));

        return output;
    } catch (error) {
        console.error("Error getting ELA standard:", error);
    }
}

export async function getSocialStudiesStandards(session, state) {
    try {
        const token = await session.getToken({ template: "userinfo" });
        setAxiosToken(token);
        const response = await axiosInstance.get(
            `${IS_DEBUG ? API_ROOT_DEBUG : API_ROOT}/skill?standard_set=${state}&subject=history`,
        );
        return response.data.sort((a, b) => a.id.localeCompare(b.id));
    } catch (error) {
        console.error("Error getting social studies standards:", error);
    }
}

export async function getScienceStandards(session, state) {
    try {
        const token = await session.getToken({ template: "userinfo" });
        setAxiosToken(token);
        const response = await axiosInstance.get(
            `${IS_DEBUG ? API_ROOT_DEBUG : API_ROOT}/skill?standard_set=${state}&subject=science`,
        );
        return response.data.sort((a, b) => a.id.localeCompare(b.id));
    } catch (error) {
        console.error("Error getting science standards:", error);
    }
}
